const DEVELOPMENT = "development";
const PRODUCTION = "production";
const TEST = "test";
const STAGING = "staging";

export const ENV = {
  PRODUCTION,
  DEVELOPMENT,
  STAGING,
  TEST
};

export const ENV_VALUES = Object.values(ENV);
