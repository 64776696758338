import SERVICES from "utils/constants/services";
import compact from "lodash/compact";
import uniq from "lodash/uniq";
import { MOCK_UUID } from "./constants/request";
import { getConfig } from "./env";

const BUILDER_APPLICATIONS_ROUTES = {
  BUILDER_APPLICATIONS: "/builder/applications",
  BUILDER_APPLICATIONS_SHOW: "/builder/applications/[application-uuid]",
  BUILDER_APPLICATIONS_PAGES: "/builder/applications/[application-uuid]/pages",
  BUILDER_APPLICATIONS_PAGES_CREATE:
    "/builder/applications/[application-uuid]/pages/create",
  BUILDER_APPLICATIONS_PAGES_BUILDER:
    "/builder/applications/[application-uuid]/pages/builder",
  BUILDER_APPLICATIONS_PAGES_NEW:
    "/builder/applications/[application-uuid]/pages/new",
  BUILDER_APPLICATIONS_PAGES_SHOW:
    "/builder/applications/[application-uuid]/pages/[page-uuid]",
  BUILDER_APPLICATIONS_PAGES_SHOW_SECTION:
    "/builder/applications/[application-uuid]/pages/[page-uuid]/sections/[section-uuid]",
  /**
   * Deep builder overlay paths
   */
  BUILDER_APPLICATIONS_PAGES_SHOW_SECTION_PRODUCTS:
    "/builder/applications/[application-uuid]/pages/[page-uuid]/sections/[section-uuid]/products/[product-uuid]",
  BUILDER_APPLICATIONS_PAGES_SHOW_SECTION_PRODUCTS_PRICES:
    "/builder/applications/[application-uuid]/pages/[page-uuid]/sections/[section-uuid]/products/[product-uuid]/prices/[price-uuid]",
  BUILDER_APPLICATIONS_PAGES_SHOW_SECTION_PRODUCTS_LINKS:
    "/builder/applications/[application-uuid]/pages/[page-uuid]/sections/[section-uuid]/products/[product-uuid]/links/[short-link-uuid]"
};

const PATHS = {
  // --- Authenticated ---
  HUB: "/hub",
  HUB_APPLICATIONS_SHOW: "/hub/applications/[application-uuid]",
  /**
   * App Hub Sections
   */
  HUB_APPLICATIONS_HOME: "/hub/applications/[application-uuid]/home",
  HUB_APPLICATIONS_ANALYTICS: "/hub/applications/[application-uuid]/analytics",
  HUB_APPLICATIONS_PAGES: "/hub/applications/[application-uuid]/pages",
  HUB_APPLICATIONS_SETTINGS: "/hub/applications/[application-uuid]/settings",
  /**
   * App Hub Products
   */
  HUB_APPLICATIONS_PRODUCTS: "/hub/applications/[application-uuid]/products",
  HUB_APPLICATIONS_PRODUCTS_SHOW:
    "/hub/applications/[application-uuid]/products/[product-uuid]",
  HUB_APPLICATIONS_PRODUCTS_PRICES_SHOW:
    "/hub/applications/[application-uuid]/products/[product-uuid]/prices/[price-uuid]",
  HUB_APPLICATIONS_PRODUCTS_LINKS_SHOW:
    "/hub/applications/[application-uuid]/products/[product-uuid]/links/[short-link-uuid]",
  /**
   * App Hub Short Links
   */
  HUB_APPLICATIONS_LINKS: "/hub/applications/[application-uuid]/links",
  HUB_APPLICATIONS_LINKS_CREATE:
    "/hub/applications/[application-uuid]/links/create",
  HUB_APPLICATIONS_LINKS_SHOW:
    "/hub/applications/[application-uuid]/links/[short-link-uuid]",
  /**
   * App Hub Coupons
   */
  HUB_APPLICATIONS_COUPONS: "/hub/applications/[application-uuid]/coupons",
  HUB_APPLICATIONS_COUPONS_SHOW:
    "/hub/applications/[application-uuid]/coupons/[coupon-uuid]",
  /**
   * App Hub Tax Rates
   */
  HUB_APPLICATIONS_TAX_RATES: "/hub/applications/[application-uuid]/tax-rates",
  HUB_APPLICATIONS_TAX_RATES_SHOW:
    "/hub/applications/[application-uuid]/tax-rates/[tax-rate-uuid]",
  /**
   * App Hub Tax Rates
   */
  HUB_APPLICATIONS_SHIPPING_RATES:
    "/hub/applications/[application-uuid]/shipping-rates",
  HUB_APPLICATIONS_SHIPPING_RATES_SHOW:
    "/hub/applications/[application-uuid]/shipping-rates/[shipping-rate-uuid]",
  /**
   * App Hub Attachments
   */
  HUB_APPLICATIONS_FILES: "/hub/applications/[application-uuid]/files",
  HUB_APPLICATIONS_FILES_SHOW:
    "/hub/applications/[application-uuid]/files/[attachment-uuid]",
  /**
   * App Hub LegalContents
   */
  HUB_APPLICATIONS_LEGAL_CONTENTS:
    "/hub/applications/[application-uuid]/legal-contents",
  /**
   * Note these paths should share the use of the params
   */
  HUB_APPLICATIONS_LEGAL_CONTENTS_SHOW:
    "/hub/applications/[application-uuid]/legal-contents/[legal-content-uuid]",
  /**
   * App Hub integrations
   */
  HUB_APPLICATIONS_INTEGRATIONS:
    "/hub/applications/[application-uuid]/integrations",
  HUB_APPLICATIONS_INTEGRATIONS_CREATE:
    "/hub/applications/[application-uuid]/integrations/create",
  HUB_APPLICATIONS_INTEGRATIONS_SHOW:
    "/hub/applications/[application-uuid]/integrations/[integration-uuid]",
  /**
   * App Hub datasources
   */
  HUB_APPLICATIONS_DATASOURCES:
    "/hub/applications/[application-uuid]/datasources",
  HUB_APPLICATIONS_DATASOURCES_SHOW:
    "/hub/applications/[application-uuid]/datasources/[datasource-uuid]",

  /**
   * App Hub Campaigns
   */
  HUB_APPLICATIONS_CAMPAIGNS: "/hub/applications/[application-uuid]/campaigns",
  HUB_APPLICATIONS_CAMPAIGNS_BUILDER:
    "/hub/applications/[application-uuid]/campaigns/builder",
  HUB_APPLICATIONS_CAMPAIGNS_SHOW:
    "/hub/applications/[application-uuid]/campaigns/[campaign-uuid]",
  /**
   * App Hub Sales
   */
  HUB_APPLICATIONS_SALES: "/hub/applications/[application-uuid]/sales",
  HUB_APPLICATIONS_SALES_SHOW:
    "/hub/applications/[application-uuid]/sales/[sale-uuid]",

  /**
   * Features
   */
  FEATURES_PAGES_SUBSCRIPTIONS: "/features/pages/subscriptions",
  FEATURES_PAGES_CREATORS: "/features/pages/creators",
  FEATURES_CHANNELS: "/features/channels",
  FEATURES_CONTACTLESS: "/features/contactless",
  FEATURES_CHECKOUT: "/features/checkout",
  FEATURES_ANALYTICS: "/features/analytics",
  FEATURES_DIGITAL_CONTENT: "/features/digital-content",
  FEATURES_INTEGRATIONS_ZAPIER: "/features/integrations/zapier",
  FEATURES_INTEGRATIONS_STRIPE: "/features/integrations/stripe",
  FEATURES_INTEGRATIONS_WEBFLOW: "/features/integrations/webflow",
  FEATURES_LINKS: "/features/links",
  FEATURES_LINKS_API: "/features/links/api",
  FEATURES_LINKS_ADVANCED: "/features/links/advanced",
  FEATURES_LINKS_BUILDER: "/features/links/builder",
  FEATURES_LINKS_BUILDER_SHOW: "/features/links/builder/[short-id]",
  FEATURES_PAGES_BUILDER: "/features/pages/builder",
  FEATURES_PAGES_BUILDER_SHOW: "/features/pages/builder/[page-uuid]",

  /**
   * Tools
   */
  TOOLS_QR_CODES: "/tools/qr-codes",
  TOOLS_QR_CODES_STRIPE: "/tools/qr-codes/stripe",
  TOOLS_QR_CODES_USA: "/tools/qr-codes/usa",

  /**
   * UseCases
   */
  USE_CASES_PAYMENT_LINKS_AUTHORIZATION:
    "/use-cases/payment-links/authorization",

  /**
   * Docs
   */
  DOCS: "/docs",
  DOCS_INTRODUCTION: "/docs/introduction/[slug]",

  /**
   * Developers
   */
  DEVELOPERS: "/developers",
  DEVELOPERS_INTRODUCTION: "/developers/introduction/[slug]",

  /**
   * Tutorials
   */
  TUTORIALS: "/tutorials",
  TUTORIALS_SHOW: "/tutorials/[slug]",
  TUTORIALS_EMAIL_SHOW: "/tutorials/email/[slug]",

  /**
   * Learn
   */
  LEARN: "/learn",
  LEARN_SHOW: "/learn/[slug]",
  LEARN_EMAIL_SHOW: "/learn/email/[slug]",

  ONBOARDING: "/onboarding",
  MARKETPLACE: "/marketplace",
  TEAM: "/team",
  QUIZ: "/quiz",
  EVALUATE: "/evaluate",
  COMPARE: "/compare",
  GOALS: "/goals",
  PLAN: "/plan",
  PROFILE: "/profile",
  PRICING: "/pricing",
  PRICING_CONTEXT: "/pricing/context",
  SUPPORT: "/support",
  HELP: "/help",
  CHECKOUT: "/checkout",
  CHECKOUT_SUCCESS: "/checkout/success",
  CHECKOUT_CONTENT: "/checkout/content",
  CHECKOUT_ERROR: "/checkout/error",
  CHECKOUT_COMPLETED: "/checkout/completed",
  BILLING_MANAGE_STATUS: "/billing/manage/status",
  SECRET_TEST: "/secret-test",
  SCRATCH: "/scratch",
  // --- PAGES ---
  PAGES: "/pages",
  TIP: "/tip",
  // --- CHECKOUTS ---
  // Note: [short-id] is actually a uuid value but keeping name convention w/links pages
  CHECKOUTS: "/co",
  CHECKOUTS_SHOW: "/co/[short-id]",
  CHECKOUTS_SETUP: "/co/[short-id]/setup",
  // --- LINKS ---
  LINKS: "/links",
  LINKS_SHOW: "/links/[short-id]",
  LINKS_SETUP: "/links/[short-id]/setup",
  // --- APPLICATIONS ---
  APPLICATIONS: "/applications",
  APPLICATIONS_SHOW: "/applications/[application-uuid]",
  APPLICATIONS_PAGES: "/applications/[application-uuid]/pages",
  APPLICATIONS_PAGES_CREATE: "/applications/[application-uuid]/pages/create",
  APPLICATIONS_PAGES_BUILDER: "/applications/[application-uuid]/pages/builder",
  APPLICATIONS_PAGES_NEW: "/applications/[application-uuid]/pages/new",
  APPLICATIONS_PAGES_SHOW: "/applications/[application-uuid]/pages/[page-uuid]",
  APPLICATIONS_PAGES_SHOW_SECTION:
    "/applications/[application-uuid]/pages/[page-uuid]/sections/[section-uuid]",
  /**
   * Deep builder overlay paths
   */
  APPLICATIONS_PAGES_SHOW_SECTION_PRODUCTS:
    "/applications/[application-uuid]/pages/[page-uuid]/sections/[section-uuid]/products/[product-uuid]",
  APPLICATIONS_PAGES_SHOW_SECTION_PRODUCTS_PRICES:
    "/applications/[application-uuid]/pages/[page-uuid]/sections/[section-uuid]/products/[product-uuid]/prices/[price-uuid]",
  APPLICATIONS_PAGES_SHOW_SECTION_PRODUCTS_LINKS:
    "/applications/[application-uuid]/pages/[page-uuid]/sections/[section-uuid]/products/[product-uuid]/links/[short-link-uuid]",

  // ======== Builder Version =============
  // --- APPLICATIONS ---
  ...BUILDER_APPLICATIONS_ROUTES,
  // ============================

  OAUTH: "/oauth",
  // --- Authentication ---
  LOGIN: "/login",
  SIGNUP: "/signup",
  RESET: "/reset",
  RESET_CONFIRM: "/reset-confirm",
  EXCHANGE: "/exchange",
  EXCHANGE_STRIPE: "/exchange/stripe",
  OAUTH_INITIATE_SERVICE: "/oauth/[service]/initiate",
  EXCHANGE_INTEGRATIONS_SERVICE: "/exchange/integrations/[service]",
  // --- Legal ---
  PRIVACY: "/privacy",
  TERMS: "/terms",
  COOKIE_POLICY: "/cookie-policy",
  // --- General ---
  ERROR: "/error",
  RUNTIME: "/runtime",
  // --- Marketing Pages ---
  HOME: "/",
  LEVELS_EXPLAINED: "/levels-explained",
  EVALUATIONS_EXPLAINED: "/evaluations-explained",
  GOALS_EXPLAINED: "/goals-explained",
  PRODUCTS_EXPLAINED: "/products-explained",
  TEAM_EXPLAINED: "/team-explained",
  // --- Guest Pages ---
  GUEST_EVALUATE: "/guest-evaluate",
  GUEST_COMPARE: "/guest-compare",
  /**
   * Test routes
   */
  TEST_LINKS: "/test/links",
  TEST_LINKS_SHOW: "/test/links/[short-id]",
  TEST_LINKS_SETUP: "/test/links/[short-id]/setup",
  TEST_PAY: "/test/pay",
  TEST_CHECKOUT_SUCCESS: "/test/checkout/success",
  TEST_CHECKOUT_SUCCESS_DEMO: "/test/checkout/success/demo",
  TEST_CHECKOUT_CONTENT: "/test/checkout/content",
  TEST_CHECKOUT_ERROR: "/test/checkout/error",
  TEST_CHECKOUT_COMPLETED: "/test/checkout/completed"
};

export const APP_ALIAS_RE = new RegExp(`^/${getConfig("APP_ALIAS")}`);

export const EXCLUSIVE_PATHS = uniq(
  compact([
    ...Object.values(PATHS).map((fragment) => compact(fragment.split("/"))[0]),
    getConfig("APP_ALIAS"),
    "meta",
    "sitemap",
    "www",
    "dev",
    "prod",
    "shop",
    "staging",
    "alpha",
    "beta",
    "promo",
    "products",
    "priceblocs",
    "demo",
    "test",
    "example",
    "product",
    "receipt",
    "checkout",
    "checkouts",
    "error",
    "store",
    "admin",
    // Payment verbs
    "pay",
    "contactless",
    "qrcode",
    "buy",
    "purchase",
    "payment",
    "fee",
    "exchange",
    // Companies
    "crypto",
    "google",
    "apple",
    "netflix",
    "microsoft",
    "facebook",
    "meta",
    "instagram",
    "whatsapp"
  ])
);

export const EXCLUSIVE_PATHS_MAP = EXCLUSIVE_PATHS.reduce((memo, key) => {
  memo[key] = key;
  return memo;
}, {});

export const SUBDOMAIN_ROUTES = [
  PATHS.PRICING,
  PATHS.PRICING_CONTEXT,
  PATHS.CHECKOUT,
  PATHS.CHECKOUT_SUCCESS,
  PATHS.CHECKOUT_CONTENT,
  PATHS.CHECKOUT_ERROR,
  PATHS.TEST_CHECKOUT_SUCCESS,
  PATHS.TEST_CHECKOUT_SUCCESS_DEMO,
  PATHS.TEST_CHECKOUT_CONTENT,
  PATHS.TEST_CHECKOUT_ERROR
];

export const UNAUTHED_ROUTES = [
  PATHS.HOME,
  PATHS.LOGIN,
  PATHS.SECRET_TEST,
  PATHS.SCRATCH,
  PATHS.MARKETPLACE,
  PATHS.GUEST_EVALUATE,
  PATHS.GUEST_COMPARE,
  PATHS.GOALS,
  PATHS.SIGNUP,
  PATHS.OAUTH,
  PATHS.SUPPORT,
  PATHS.RESET,
  PATHS.ERROR,
  PATHS.RESET_CONFIRM,
  PATHS.PRIVACY,
  PATHS.COOKIE_POLICY,
  PATHS.TERMS,
  PATHS.LEVELS_EXPLAINED,
  PATHS.GOALS_EXPLAINED,
  PATHS.PRODUCTS_EXPLAINED,
  PATHS.TEAM_EXPLAINED,
  PATHS.EVALUATIONS_EXPLAINED,
  PATHS.RUNTIME,
  PATHS.EXCHANGE,
  PATHS.LINKS_SHOW,
  PATHS.LINKS_SETUP,
  PATHS.TEST_LINKS_SHOW,
  PATHS.TEST_LINKS_SETUP,
  PATHS.FEATURES_ANALYTICS,
  PATHS.FEATURES_PAGES_SUBSCRIPTIONS,
  PATHS.FEATURES_PAGES_CREATORS,
  PATHS.FEATURES_CHANNELS,
  PATHS.FEATURES_LINKS,
  PATHS.FEATURES_LINKS_ADVANCED,
  PATHS.FEATURES_LINKS_API,
  PATHS.FEATURES_CHECKOUT,
  PATHS.FEATURES_CONTACTLESS,
  PATHS.FEATURES_DIGITAL_CONTENT,
  PATHS.FEATURES_INTEGRATIONS_ZAPIER,
  PATHS.FEATURES_INTEGRATIONS_STRIPE,
  PATHS.FEATURES_INTEGRATIONS_WEBFLOW,
  PATHS.FEATURES_LINKS_BUILDER,
  PATHS.FEATURES_LINKS_BUILDER_SHOW,
  PATHS.FEATURES_PAGES_BUILDER,
  PATHS.FEATURES_PAGES_BUILDER_SHOW,
  ...Object.values(BUILDER_APPLICATIONS_ROUTES),
  PATHS.TOOLS_QR_CODES,
  PATHS.TOOLS_QR_CODES_STRIPE,
  PATHS.TOOLS_QR_CODES_USA,
  PATHS.USE_CASES_PAYMENT_LINKS_AUTHORIZATION,
  PATHS.TUTORIALS,
  PATHS.TUTORIALS_SHOW,
  PATHS.TUTORIALS_EMAIL_SHOW,
  ...SUBDOMAIN_ROUTES
];

export const AUTHED_REDIRECT_ROUTES = [PATHS.EXCHANGE_STRIPE];
export const MIXED_AUTH_ROUTES = [PATHS.PRICING];
export const PLATFORM_PAGE_ROUTES = { [PATHS.PRICING]: true };
export const NAV_HIDE_ROUTES = [
  PATHS.APPLICATIONS_PAGES_SHOW,
  PATHS.APPLICATIONS_PAGES_SHOW_SECTION
];

export const OAUTH_ROUTES = {
  GOOGLE: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/auth/google/identity/web`,
  SLACK: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/auth/slack/identity/web`,
  SHOPIFY: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/auth/shopify/identity/web`,
  STRIPE: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/auth/stripe/identity/web`,
  SQUARESPACE: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/auth/squarespace/identity/web`,
  DOCUSIGN: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/auth/docusign/identity/web`,
  SLACK_BOT: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/auth/slack/bot/web`
};

export const THIRD_PARTY_SCRIPTS = {
  RECAPTCHA: "https://www.google.com/recaptcha/api.js"
};

export const EXTERNAL = {
  DATACLIPS: `https://data.heroku.com/dataclips`,
  STRIPE: `https://dashboard.stripe.com`
};

export const BUILD_EXTERNAL = {
  [SERVICES.SQUARESPACE]: {
    API_KEYS: (shop) =>
      `https://${shop}.squarespace.com/config/settings/advanced/developer-api-keys`
  }
};

const STRIPE_CHECKOUT = "/stripe/checkout";
const STRIPE_BILLING = "/stripe/billing";

export const API_PATHS = {
  /**
   * Document & Render
   */
  DOCUMENT: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/document`,
  RENDER: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/render`,
  /**
   * ShortLinks
   */
  LINKS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/links`,
  TEST_LINKS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/test/links`,
  /**
   * Pay
   */
  PAYS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/pay`,
  TEST_PAYS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/test/pay`,
  /**
   * Redirects
   */
  REDIRECTS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/redirects`,
  /**
   * Checkout & Checkout flex
   */
  CHECKOUT: `${process.env.NEXT_PUBLIC_API_ROOT}/v1${STRIPE_CHECKOUT}`,
  CHECKOUT_FLEX: `${process.env.NEXT_PUBLIC_API_ROOT}/v1${STRIPE_CHECKOUT}/flex`,
  CHECKOUT_FORM: `${process.env.NEXT_PUBLIC_API_ROOT}/v1${STRIPE_CHECKOUT}/form`,
  CHECKOUT_CART: `${process.env.NEXT_PUBLIC_API_ROOT}/v1${STRIPE_CHECKOUT}/cart`,
  CHECKOUT_IDENTITY: `${process.env.NEXT_PUBLIC_API_ROOT}/v1${STRIPE_CHECKOUT}/identity`,
  /**
   * Checkout Success
   */
  CHECKOUT_SUCCESS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1${STRIPE_CHECKOUT}/success`,
  TEST_CHECKOUT_SUCCESS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/test${STRIPE_CHECKOUT}/success`,
  /**
   * Checkout Error
   */
  CHECKOUT_ERROR: `${process.env.NEXT_PUBLIC_API_ROOT}/v1${STRIPE_CHECKOUT}/error`,
  TEST_CHECKOUT_ERROR: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/test${STRIPE_CHECKOUT}/error`,
  /**
   * Checkout Content
   */
  CHECKOUT_CONTENT: `${process.env.NEXT_PUBLIC_API_ROOT}/v1${STRIPE_CHECKOUT}/content`,
  TEST_CHECKOUT_CONTENT: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/test${STRIPE_CHECKOUT}/content`,
  /**
   * Billing Manage
   */
  BILLING_MANAGE_STATUS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1${STRIPE_BILLING}/manage/status`,
  TEST_BILLING_MANAGE_STATUS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/test${STRIPE_BILLING}/manage/status`,
  /**
   * Checkout && Billing Sessions
   */
  CHECKOUTS_SESSION: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/checkouts/sessions`,
  BILLING_SESSION: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/billing/sessions`,
  /**
   * Identity
   */
  IDENTITY_TIN_VALIDATE: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/identity/tin/validate`,
  IDENTITY_VERIFICATION_STATUS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/identity/verification/status`,
  IDENTITY_VERIFICATION_CANCEL: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/identity/verification/cancel`,
  IDENTITY_VERIFICATION_REDACT: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/identity/verification/redact`,
  /**
   * Authentication
   */
  STRIPE_AUTH: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/auth/stripe/identity/web`,
  STRIPE_TEST_AUTH: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/auth/stripe/identity/web/test`,
  /**
   * Configuration
   */
  PRICING_CONFIG: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/config/pricing`,
  /**
   * Attachments
   */
  ATTACHMENTS_ANON: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/attachments/anon`,
  /**
   * Commands
   */
  COMMANDS: `${process.env.NEXT_PUBLIC_API_ROOT}/v1/app/commands`,
  /**
   * Manifests validation
   */
  MANIFESTS_FIELDS_VALIDATE: ({ manifestId, fieldUUID }) =>
    `${process.env.NEXT_PUBLIC_API_ROOT}/v1/manifests/${manifestId}/fields/${fieldUUID}/validate`
};

export const ASSET_PATHS = {
  STRIPE: `https://files.stripe.com/links`
};

export const LINKS_RE = /^\/links\//i;
export const TEST_LINKS_RE = /^\/test\/links\//i;
export const SETUP_LINKS_RE = /\/links\/.*\/setup$/i;
export const BUILDER_LINKS_RE = /^\/links\/builder/i;
export const HIDE_PLATFORM_SCRIPT_PATHS = [
  LINKS_RE,
  TEST_LINKS_RE,
  SETUP_LINKS_RE
];

export const HIDE_PLATFORM_SECURITY_SCRIPT_PATHS = [LINKS_RE, TEST_LINKS_RE];

export const apiResourcePath = ({ resource, resourceUUID, test }) =>
  compact([
    `${process.env.NEXT_PUBLIC_API_ROOT}/v1`,
    test || resourceUUID === MOCK_UUID ? "test" : "",
    `${resource}s/${resourceUUID}`
  ]).join("/");

export default PATHS;

const LEARN_ROOT = `${getConfig("WEB_CLIENT_ROOT")}${PATHS.LEARN}`;
export const LEARN_PATHS = {
  PAYMENT_LINKS: {
    OVERVIEW: `${LEARN_ROOT}/payment-links/overview`,
    PAYMENT_METHODS_RESTRICTIONS: `${LEARN_ROOT}/payment-links/payment-methods#restrictions`,
    PAYMENT_DETAILS_RESTRICTIONS: `${LEARN_ROOT}/payment-links/payment-details#restrictions`
  },
  PAYMENT_PAGES: {
    OVERVIEW: `${LEARN_ROOT}/payment-pages/overview`
  },
  AUTOMATION: {
    OVERVIEW: `${LEARN_ROOT}/automation/overview`,
    ZAPIER: {
      OVERVIEW: `${LEARN_ROOT}/automation/zapier/overview`,
      APP: `${LEARN_ROOT}/automation/zapier/app`,
      SETUP: `${LEARN_ROOT}/automation/zapier/setup`,
      FORMAT: `${LEARN_ROOT}/automation/zapier/format`,
      FILTER: `${LEARN_ROOT}/automation/zapier/filter`,
      EXAMPLE: `${LEARN_ROOT}/automation/zapier/example`
    }
  }
};

const DOCS_ROOT = `${getConfig("WEB_CLIENT_ROOT")}${PATHS.DOCS}`;

export const DOCS_PATHS = {
  INTRODUCTION: `${DOCS_ROOT}/introduction/overview`,
  ERRORS: `${DOCS_ROOT}/errors/overview`,
  AUTHENTICATION: `${DOCS_ROOT}/authentication/overview`,
  PAYMENTS: {
    PAYMENT_LINKS: {
      OVERVIEW: `${DOCS_ROOT}/payments/payment-links/overview`,
      OBJECT: `${DOCS_ROOT}/payments/payment-links/object`,
      CREATE: `${DOCS_ROOT}/payments/payment-links/create`,
      RETRIEVE: `${DOCS_ROOT}/payments/payment-links/retrieve`,
      UPDATE: `${DOCS_ROOT}/payments/payment-links/update`,
      LIST: `${DOCS_ROOT}/payments/payment-links/list`,
      DELETE: `${DOCS_ROOT}/payments/payment-links/delete`,
      VALIDATIONS: `${DOCS_ROOT}/payments/payment-links/validations`
    },
    CHECKOUTS: {
      OVERVIEW: `${DOCS_ROOT}/payments/checkouts/overview`,
      OBJECT: `${DOCS_ROOT}/payments/checkouts/object`,
      RETRIEVE: `${DOCS_ROOT}/payments/checkouts/retrieve`,
      LIST: `${DOCS_ROOT}/payments/checkouts/list`
    }
  }
};

const DEVELOPERS_ROOT = `${getConfig("WEB_CLIENT_ROOT")}${PATHS.DEVELOPERS}`;

export const DEVELOPERS_PATHS = {
  INTRODUCTION: `${DEVELOPERS_ROOT}/introduction/overview`,
  ERRORS: `${DEVELOPERS_ROOT}/errors/overview`,
  AUTHENTICATION: `${DEVELOPERS_ROOT}/authentication/overview`,
  PAYMENTS: {
    PAYMENT_LINKS: {
      OVERVIEW: `${DEVELOPERS_ROOT}/payments/payment-links/overview`,
      OBJECT: `${DEVELOPERS_ROOT}/payments/payment-links/object`,
      CREATE: `${DEVELOPERS_ROOT}/payments/payment-links/create`,
      RETRIEVE: `${DEVELOPERS_ROOT}/payments/payment-links/retrieve`,
      UPDATE: `${DEVELOPERS_ROOT}/payments/payment-links/update`,
      LIST: `${DEVELOPERS_ROOT}/payments/payment-links/list`,
      DELETE: `${DEVELOPERS_ROOT}/payments/payment-links/delete`,
      VALIDATIONS: `${DEVELOPERS_ROOT}/payments/payment-links/validations`
    },
    CHECKOUTS: {
      OVERVIEW: `${DEVELOPERS_ROOT}/payments/checkouts/overview`,
      OBJECT: `${DEVELOPERS_ROOT}/payments/checkouts/object`,
      RETRIEVE: `${DEVELOPERS_ROOT}/payments/checkouts/retrieve`,
      LIST: `${DEVELOPERS_ROOT}/payments/checkouts/list`
    }
  }
};
