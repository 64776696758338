import {
  APPLICATION,
  CAMPAIGN,
  ATTACHMENT,
  PRODUCT,
  PRICE,
  PAGE,
  LINK,
  SHORT_LINK,
  COUPON,
  SALE,
  TAX_RATE,
  SHIPPING_RATE,
  FILE,
  SECTION,
  LEGAL_CONTENT,
  INTEGRATION
} from "utils/constants/models";
import kebabCase from "lodash/kebabCase";

const APPLICATION_UUID = `${APPLICATION}-uuid`;
const PRODUCT_UUID = `${PRODUCT}-uuid`;
const PRICE_UUID = `${PRICE}-uuid`;
const PAGE_UUID = `${PAGE}-uuid`;
const SHORT_LINK_UUID = `${kebabCase(SHORT_LINK)}-uuid`;
const COUPON_UUID = `${COUPON}-uuid`;
const SALE_UUID = `${SALE}-uuid`;
const ATTACHMENT_UUID = `${ATTACHMENT}-uuid`;
const CAMPAIGN_UUID = `${CAMPAIGN}-uuid`;
const INTEGRATION_UUID = `${INTEGRATION}-uuid`;
const TAX_RATE_UUID = `${kebabCase(TAX_RATE)}-uuid`;
const LEGAL_CONTENT_UUID = `${kebabCase(LEGAL_CONTENT)}-uuid`;
const SHIPPING_RATE_UUID = `${kebabCase(SHIPPING_RATE)}-uuid`;
const SECTION_UUID = `${SECTION}-uuid`;

export const QUERY_PARAM_KEY = {
  APPLICATION_UUID,
  ATTACHMENT_UUID,
  CAMPAIGN_UUID,
  SECTION_UUID,
  PRODUCT_UUID,
  PRICE_UUID,
  PAGE_UUID,
  SHORT_LINK_UUID,
  COUPON_UUID,
  SALE_UUID,
  TAX_RATE_UUID,
  SHIPPING_RATE_UUID,
  LEGAL_CONTENT_UUID,
  INTEGRATION_UUID
};

export const MODEL_PARAM_KEY_MAP = {
  [`${APPLICATION}s`]: APPLICATION_UUID,
  [`${ATTACHMENT}s`]: ATTACHMENT_UUID,
  [`${FILE}s`]: ATTACHMENT_UUID,
  [`${CAMPAIGN}s`]: CAMPAIGN_UUID,
  [`${INTEGRATION}s`]: INTEGRATION_UUID,
  [`${PRODUCT}s`]: PRODUCT_UUID,
  [`${PRICE}s`]: PRICE_UUID,
  [`${PAGE}s`]: PAGE_UUID,
  [`${LINK}s`]: SHORT_LINK_UUID,
  [`${kebabCase(SHORT_LINK)}s`]: SHORT_LINK_UUID,
  [`${COUPON}s`]: COUPON_UUID,
  [`${SALE}s`]: SALE_UUID,
  [`${kebabCase(TAX_RATE)}s`]: TAX_RATE_UUID,
  [`${kebabCase(SHIPPING_RATE)}s`]: SHIPPING_RATE_UUID,
  [`${kebabCase(LEGAL_CONTENT)}s`]: LEGAL_CONTENT_UUID,
  [`${SECTION}s`]: SECTION_UUID
};

const UTM_SOURCE = "utm_source";
const UTM_MEDIUM = "utm_medium";
const UTM_CAMPAIGN = "utm_campaign";
const UTM_CONTENT = "utm_content";

/**
 * Silence event tracking
 */
export const SILENT = "silent";

const GOOGLE_UTM = {
  UTM_SOURCE,
  UTM_MEDIUM,
  UTM_CAMPAIGN,
  UTM_CONTENT
};

const ADWORDS_CID = "cid";
const ADWORDS_GCLID = "gclid";
export const ADWORDS_UID = {
  ADWORDS_CID,
  ADWORDS_GCLID
};

/**
 * Mailchimp params
 * https://mailchimp.com/developer/marketing/docs/e-commerce/#tracking-and-reports
 * mc_cid - campaign number
 * mc_eid - list id
 * mc_tc - tracking code
 */
const MC_CID = "mc_cid";
const MC_EID = "mc_eid";
const MC_TC = "mc_tc";
export const MAILCHIMP_UID = {
  MC_CID,
  MC_EID,
  MC_TC
};

const VIA = "via";
const REFERRAL = "referral";
const REWARDFUL = {
  VIA,
  REFERRAL
};

export const THIRD_PARTY_QUERY_PARAMS = {
  ...GOOGLE_UTM,
  ...ADWORDS_UID,
  ...MAILCHIMP_UID,
  ...REWARDFUL
};

export const THIRD_PARTY_QUERY_PARAM_KEYS = Object.values(
  THIRD_PARTY_QUERY_PARAMS
);
