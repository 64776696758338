const EMAIL = "email";
const VIDEO = "video";
const SMS = "sms";
const CONTACTLESS = "contactless";
const SUPPORT = "support";
const SOCIAL = "social";
const COMMERCE = "commerce";

export const USE_CASES = {
  EMAIL,
  VIDEO,
  SMS,
  CONTACTLESS,
  SUPPORT,
  SOCIAL,
  COMMERCE
};

export const USE_CASE_ICON_LABEL_MAP = {
  [EMAIL]: "Email",
  [VIDEO]: "Video",
  [SMS]: "SMS",
  [CONTACTLESS]: "Contactless",
  [SUPPORT]: "Support",
  [SOCIAL]: "Social",
  [COMMERCE]: "Commerce"
};

const MAILCHIMP = "mailchimp";
const INTERCOM = "intercom";
const SENDINBLUE = "sendinblue";
const HUBSPOT = "hubspot";
const CONVERT_KIT = "convert-kit";
const SENDGRID = "sendgrid";
const MAILGUN = "mailgun";
const ACTIVECAMPAIGN = "activecampaign";
const CAMPAIGN_MONITOR = "campaign-monitor";
const CONSTANT_CONTACT = "constant-contact";
const KLAVIYO = "klaviyo";

export const EMAIL_SERVICES = {
  MAILCHIMP,
  INTERCOM,
  SENDINBLUE,
  HUBSPOT,
  CONVERT_KIT,
  SENDGRID,
  MAILGUN,
  ACTIVECAMPAIGN,
  CAMPAIGN_MONITOR,
  CONSTANT_CONTACT,
  KLAVIYO
};

const INSTAGRAM = "instagram";
const LINE = "line";
const MESSENGER = "messenger";
const QQ = "qq";
const SNAPCHAT = "snapchat";
const TELEGRAM = "telegram";
const WECHAT = "wechat";
const WHATSAPP = "whatsapp";
const TWITTER = "twitter";
const FACEBOOK = "facebook";
const TIKTOK = "tiktok";
const DISCORD = "discord";

export const SOCIAL_SERVICE = {
  WHATSAPP,
  MESSENGER,
  WECHAT,
  LINE,
  QQ,
  TELEGRAM,
  INSTAGRAM,
  SNAPCHAT,
  TWITTER,
  FACEBOOK,
  TIKTOK,
  DISCORD
};

const ZOOM = "zoom";
const MEET = "meet";

export const VIDEO_SERVICES = {
  ZOOM,
  MEET
};

const TWILIO = "twilio";
const MESSAGEBIRD = "messagebird";

export const SMS_SERVICES = {
  TWILIO,
  MESSAGEBIRD
};

const IOS = "ios";
const ANDROID = "android";

export const CONTACTLESS_SERVICES = {
  IOS,
  ANDROID
};
