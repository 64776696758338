import { COLORS } from "utils/styles";
import classnames from "classnames";
import { EMAIL_SERVICES } from "./useCases";
import { isPlatformSubdomain } from "utils/subdomain";

export const FORMER_MAX_WIDTH = 1232;
export const MAX_WIDTH = 1380;
export const XXXL = 2560;
export const XXL = 1920;
export const XL = 1440;
// export const XL = 1224;
export const DESKTOP = 960;
export const MOBILE = 768;
export const PHONE = 425;
export const LAYOUT_MEDIA_QUERIES = {
  isPhone: `(max-width: ${PHONE}px)`,
  isMobile: `(min-width: ${PHONE + 1}px) and (max-width: ${DESKTOP - 1}px)`,
  isDesktop: `(min-width: ${DESKTOP}px)`
};
export const VIEWPORT_MOBILE_WIDTH = 320;
export const SPLIT_CONTAINER_SECTION_MAX_WIDTH = 396;
export const VIEWPORT_MOBILE_THRESHOLD = 812;

export const ROW_MIN_HEIGHT = { minHeight: 32 };
export const INITIAL_LOAD_COUNT = 10;
export const INPUT_HEIGHT = 42;
export const THIN_BUTTON = {
  height: 24
};

export const DELETE_ICON = "delete";
export const DELETE_TOOLTIP = "Delete";

export const CELEBRATION_DURATION = 3000;

export const SMALL_WHITE_LOADER = {
  loading: {
    sizeClass: "small"
  }
};

export const SMALL_BLUE_LOADER = {
  loading: {
    sizeClass: "small",
    ringClass: "blue"
  }
};

export const OVERFLOW_TOUCH = {
  overflowScrolling: "touch",
  WebkitOverflowScrolling: "touch"
};
export const NAV_BAR_BLOCK_HEIGHT = 52;
export const PROGRESS_BAR_HEIGHT = 4;
export const TOP_CONTROL = 54;

export const FIXED_OVERSCROLL = {
  top: 0,
  bottom: 0,
  position: "fixed",
  overflowY: "scroll",
  overflowX: "hidden"
};
export const DOT_DIMENSION = 8;
// Fixed positions for search controls
export const MOBILE_TOP_FIXED_POSITION = 112;
export const TOP_FIXED_POSITION = 124;
export const FILTER_MENU_SCROLL_MARK = 120;
export const FILTERS_MENU_WIDTH_DESKTOP = 210;
export const PLAN_SCHEDULE_MIN_WIDTH = 300;
export const SEARCH_CONTROLS_HEADER_ADJ = 76;
export const REFINEMENTS_ADJ = 76;
export const CONTROLS_SCROLL_MARK = 130;
export const CURRENT_REFINEMENTS_PADDING = 42;
export const NAV_BAR_HEIGHT = 56;
export const SIDE_BAR_WIDTH = 40;
export const HAIRLINE = 6;
export const FIXED_BOTTOM_ACTION_BUTTON_STYLES = {
  bottom: -24,
  paddingBottom: 36
};

export const SPLIT_CONTENT_MAX_WIDTH = 920;
export const SPLIT_CONTENT_SECTION_MAX_WIDTH = 380;

export const NAV_BAR_STYLES = { height: NAV_BAR_HEIGHT };

export const CTA_CUSTOM_STYLES = {
  customStyles: {
    container: {
      backgroundColor: COLORS.white
    }
  }
};

export const APP_CONTENT_MAX_WIDTH =
  "w-100 flex flex-column mw-app center relative";

export const MAIN_CONTENT_MAX_WIDTH =
  "w-100 flex flex-column mw8 center relative";

export const STICKY_HEADER_CLASS = "sticky bg-white z-5 pa3";

export const BACK_CONTROL_CLASS = "flex justify-between items-center pa3";

export const HUB_CLASSES = {
  container: "w-100 bg-white pt3"
};

export const HUB_DASHBOARD_CLASSES = {
  container: APP_CONTENT_MAX_WIDTH,
  inputWrapper: "flex flex-row items-center w-100 pr2 pl2-ns",
  input:
    "f5 f6-ns border-box input-reset ba pa2 db w-100 br2 lh-copy b--black-20"
};

export const CTA_STYLE = {
  customClasses: {
    container:
      "overflow-y-hidden-ns overflow-x-hidden-ns br2 pa3 hairline-1 shadow-0 ba"
  },
  ...CTA_CUSTOM_STYLES
};

export const customCTASyle = (image = "", customClasses = {}) => ({
  customClasses: {
    container:
      "overflow-y-hidden-ns overflow-x-hidden-ns br2 pa3 hairline-1 shadow-0 ba relative",
    imageWrapper: `w-100 flex flex-column ${image}`,
    subheaderContainer: "flex flex-column justify-between items-start z-1",
    ...customClasses
  },
  ...CTA_CUSTOM_STYLES
});

export const getDotStyle = (color) => ({
  width: DOT_DIMENSION,
  height: DOT_DIMENSION,
  borderRadius: "100%",
  backgroundColor: color
});

export const DELETE_MODAL_CONTEXT = {
  header: "Delete",
  body: "Are you sure?"
};

export const SECONDARY_BUTTON_STYLES = {
  color: COLORS.blue,
  border: COLORS.blue,
  bgColor: COLORS.white,
  bgColorHover: COLORS.whitesmoke,
  borderWidth: 1,
  loading: {
    dotClass: "dot-blue",
    ringClass: "blue"
  }
};

export const DANGER_BUTTON_STYLES = {
  color: COLORS.danger,
  border: COLORS.danger,
  backgroundColor: COLORS.white,
  bgColorHover: COLORS.whitesmoke,
  borderWidth: 1
};

export const FIXED_SEARCH = {
  top: 55,
  padding: "18px 18px 0px",
  width: `calc(100% - ${FILTERS_MENU_WIDTH_DESKTOP}px)`,
  right: 0,
  position: "fixed",
  background: "white",
  zIndex: 1
};

export const PILL_CLASSES = {
  container: "flex items-end justify-end mv2",
  copy: "f7 pv1 ph2 br2 fw5"
};

export const TOOLTIP_POSITION = {
  RIGHT: "right",
  LEFT: "left",
  TOP: "top",
  BOTTOM: "bottom"
};

export const ONBOARDING_CONTAINER_CLASS =
  "h-100 h-auto-ns w-100 mw6-ns center pt4-ns";
/**
 * Asset classnames referenced within CTA
 */
export const ROCKET_CLIP = "cta__rocket--clip";
export const ROCKET_CLIP_80 = "cta__rocket--clip80";
export const ROCKET_CLIP_BOTTOM = "cta__rocket--clipBottom";
export const CLOUD_CLIP = "cta__cloud--clip";

/**
 * Interaction classes
 */
// touch-action manipulatino global class to prevent zoom on double tap
export const TA_MAN = "ta-man";

/**
 * Tabs classes
 */
export const SCHEDULE = "schedule";
export const PLAN = "plan";
export const RECOMMEND = "recommendations";
export const TEAM = "team";
export const GOALS = "goals";
// Compare an evaluation against a framework
export const COMPARE = "compare";
export const EVALUATE = "evaluate";
export const LEVEL = "level";
export const COLLABORATE = "collaborate";

export const MANAGE = "manage";
export const REPORTS = "reports";

export const OVERVIEW = "overview";

export const MAX_WIDTH_CELL_GRID_LAYOUT = {
  phone: 100,
  mobile: 33,
  desktop: 25,
  xl: 25,
  xxl: 25,
  xxxl: 25
};

export const CELL_GRID_LAYOUT = {
  phone: 100,
  mobile: 33,
  desktop: 25,
  xl: 25,
  xxl: 20,
  xxxl: 16.667
};

export const SECONDARY_CELL_GRID_LAYOUT = {
  phone: 100,
  mobile: 50,
  desktop: 33,
  xl: 33,
  xxl: 33,
  xxxl: 33
};

export const COMPRESSED_PICKER_GRID = {
  phone: 100,
  mobile: 100,
  desktop: 100,
  xl: 33,
  xxl: 25,
  xxxl: 20
};

export const UI_THEME = {
  SLIM: "slim",
  REGULAR: "regular"
};

export const ICON_BUTTON_PROPS = {
  colors: {
    hover: COLORS.primary,
    active: COLORS.primary,
    inactive: COLORS.primary
  }
};

export const SLIM_PILL_CLASSES = {
  container: "flex flex-row w-100 items-end justify-end",
  copy: "f7 ph2 pv1 br2 fw6 flex-shrink-0 items-center"
};

export const ACTIVE_HOVER_BUTTON_CLASSES = {
  button:
    "pa0 ma0 pointer items-center flex pa2 dib items-center justify-center bn br1 panel--action relative bg-primary",
  default: "bg-primary",
  active: "bg-primary"
};

export const BUTTON_HEIGHT_REGULAR = 40;
export const BUTTON_HEIGHT_SLIM = 32;

export const BUTTON_THEME = {
  [UI_THEME.REGULAR]: {
    height: BUTTON_HEIGHT_REGULAR
  },
  [UI_THEME.SLIM]: {
    height: BUTTON_HEIGHT_SLIM,
    ...SMALL_BLUE_LOADER
  }
};

export const ENTITY_HOVER_UNDERLINE =
  "pointer BLOCKS__editable__decoration--hover";

/**
 * When the subdomain is the platform we use the same header height as the app nav bar
 * Else we use 72 which is the entity Nav bar height
 * @param {String} subdomain
 */
export const getFeatureComparisonHeader = (subdomain) => {
  return {
    top: isPlatformSubdomain(subdomain) ? TOP_CONTROL : 72
  };
};

export const PRICING_TABLE = "pricingTable";
export const BLOCKS_PREFIX = "BLOCKS__";
export const BLOCKS_IS_HIGHLIGHTED = `${BLOCKS_PREFIX}highlight`;
const BUTTON_PRIMARY_CLASS = "button--primary";
const BUTTON_SECONDARY_CLASS = "button--secondary";
export const BUTTON_CLASS_CONTEXT = {
  PRIMARY: BUTTON_PRIMARY_CLASS,
  SECONDARY: BUTTON_SECONDARY_CLASS,
  BLOCKS_PRIMARY: `${BLOCKS_PREFIX}${BUTTON_PRIMARY_CLASS}`,
  BLOCKS_SECONDARY: `${BLOCKS_PREFIX}${BUTTON_SECONDARY_CLASS}`
};

export const BUTTON_TAGS = {
  BUTTON: "button",
  LINK: "link",
  ANCHOR: "a"
};

export const secondaryBackButton = (props) => ({
  type: "back",
  color: COLORS.blue,
  customClasses: {
    default: "bg-white",
    active: "bg-white--hover"
  },
  ...props
});

export const INPUT_SQUARE_LEFT_CLASSES = "input--common brtr-6 brbr-6";

export const SUBNAV_WIDTH = 720;
export const getSubNavWidth = (nodes) =>
  nodes.length <= 1 ? SUBNAV_WIDTH / 2 : SUBNAV_WIDTH;

/**
 * Color based status
 */
const DEFAULT = "default";
const WARNING = "warning";
const DANGER = "danger";
const SUCCESS = "success";
const NEUTRAL = "neutral";
const PREMIUM = "premium";

/**
 * Functionality based status
 */
const ACTIVE = "active";
const INACTIVE = "inactive";
const ARCHIVED = "archived";

const UNSTARTED = "unstarted";
const STARTED = "started";
const VIEWED = "viewed";

export const STATUS = {
  DEFAULT,
  WARNING,
  DANGER,
  SUCCESS,
  NEUTRAL,
  PREMIUM,
  ACTIVE,
  INACTIVE,
  ARCHIVED,
  UNSTARTED,
  STARTED,
  VIEWED
};

export const STATUS_COLORS = {
  [DEFAULT]: COLORS.text,
  [WARNING]: COLORS.warning,
  [DANGER]: COLORS.danger,
  [SUCCESS]: COLORS.successGreen,
  [NEUTRAL]: COLORS.textSecondary,
  [PREMIUM]: COLORS.gold,
  [ACTIVE]: COLORS.success,
  [INACTIVE]: COLORS.deselected,
  [ARCHIVED]: COLORS.purple,
  [UNSTARTED]: COLORS.textSecondary,
  [STARTED]: COLORS.blue,
  [VIEWED]: COLORS.purple
};

const ERROR = "error";
export const THEMES = {
  ERROR,
  WARNING
};

export const getListBorders = ({ className, isFirst, isLast }) =>
  classnames(className, {
    "br2 bt bb": isFirst && isLast,
    "br2 br--top": isFirst && !isLast,
    "bb-0 bt": !isLast,
    "br2 bt bb br--bottom": isLast && !isFirst
  });

const TABLE = "table";
const LIST = "list";
const GRID = "grid";
export const LIST_PRESENTATION = {
  TABLE,
  LIST,
  GRID
};

export const SMALL_ICON_SIZE = 14;

export const LIST_PRESENTATION_CONFIG = [
  {
    icon: LIST_PRESENTATION.LIST,
    value: LIST_PRESENTATION.LIST
  },
  {
    icon: LIST_PRESENTATION.GRID,
    value: LIST_PRESENTATION.GRID
  }
];

export const TABLE_LIST_PRESENTATION_CONFIG = [
  {
    icon: LIST_PRESENTATION.TABLE,
    value: LIST_PRESENTATION.TABLE
  },
  ...LIST_PRESENTATION_CONFIG
];

export const PRESENTATION_LOAD_COUNT = {
  [LIST_PRESENTATION.TABLE]: 50,
  [LIST_PRESENTATION.GRID]: 12,
  [LIST_PRESENTATION.LIST]: 10
};

export const VIEW_ON_STRIPE = "View on Stripe";

export const getShimmerClass = (withShimmer) =>
  withShimmer ? "shimmer" : "bg-black-05";

const NUMERIC = "numeric";
const NUMBER = "number";
const TEXT = "text";
export const INPUT_TYPE = {
  NUMERIC,
  NUMBER,
  TEXT
};

export const EXTENDED_OVERLAY_CONTENT =
  "contentSection fixed absolute-l w-100 w-50-l top-0 right-0 bottom-0 bg-white bw2 b--black-10 shadow-0 overflow-y-scroll overflow-x-hidden";

export const PRIORITY_FILL = {
  priority: true,
  layout: "fill",
  loading: "eager",
  objectFit: "cover"
};

export const PRIORITY_FIT_COVER = {
  priority: true,
  loading: "eager",
  objectFit: "cover"
};

export const PRIORITY_FIT_CONTAIN = {
  priority: true,
  loading: "eager",
  objectFit: "contain"
};

export const SERVICE_ICON_DIMENSIONS_MAP = {
  [EMAIL_SERVICES.MAILCHIMP]: {
    width: 360,
    height: 116
  },
  [EMAIL_SERVICES.INTERCOM]: {
    width: 360,
    height: 116
  },
  [EMAIL_SERVICES.SENDINBLUE]: {
    width: 360,
    height: 116
  },
  [EMAIL_SERVICES.HUBSPOT]: {
    width: 360,
    height: 116
  },
  [EMAIL_SERVICES.CONVERT_KIT]: {
    width: 360,
    height: 116
  },
  [EMAIL_SERVICES.SENDGRID]: {
    width: 360,
    height: 116
  },
  [EMAIL_SERVICES.MAILGUN]: {
    width: 360,
    height: 116
  },
  [EMAIL_SERVICES.ACTIVECAMPAIGN]: {
    width: 360,
    height: 116
  },
  [EMAIL_SERVICES.CAMPAIGN_MONITOR]: {
    width: 360,
    height: 116
  },
  [EMAIL_SERVICES.CONSTANT_CONTACT]: {
    width: 360,
    height: 116
  },
  [EMAIL_SERVICES.KLAVIYO]: {
    width: 360,
    height: 116
  }
};

export const MODEL_SEARCH_CLASSES = {
  container: "",
  option: {
    option: "f6"
  }
};

export const HOVER_BORDER = {
  border: "1px solid #E9E9ED",
  boxShadow: "0 5px 18px 0 rgb(0 0 0 / 10%)",
  borderColor: "#E9E9ED",
  borderRadius: 8,
  borderWidth: 2
};

export const getBlocksPrefix = (noBlocksStyle) =>
  noBlocksStyle ? "" : BLOCKS_PREFIX;

export const TRANSPARENT_CHAR = "-";

export const REACT_KEY = "$$typeof";

export const DEFAULT_WAVE_BG = "/assets/magic_link_bg.svg";

export const SMALL_ICON_BUTTON_PROPS = {
  color: COLORS.blue,
  customClasses: {
    default: "bg-white",
    active: "bg-white--hover"
  },
  size: 16
};

export const STUB_IMAGE_SRC =
  "https://files.stripe.com/links/fl_test_UrykKZ2lSGnHymYGXzuPexWX";

export const DEFAULT_ACTIONS_CLASSES = {
  container: "relative center lh-copy w-100 pt3 pb4",
  nodes: "flex justify-center dib tc"
};

export const OPERATOR_ICON = {
  SUBTRACT: "subtract",
  ADD: "add"
};

export const TABLE_EXTERNAL_LINK_CLASSES = {
  copy: "f6 fw5 truncate pr1",
  link:
    "link flex flex-row items-center link__decoration--hover color-primary pointer"
};

export const LIST_PICKER_CUSTOM_CLASSES = {
  option: {
    container: "pt3 pb3 flex flex-row",
    icon:
      "flex items-center justify-center bg-content--secondary br2 icon--boundary flex-shrink-0",
    label: "fw6 f5",
    description: "f6 pt1 lh-copy"
  }
};

export const SELECT_DEFAULT_PLACEHOLDER = "Select option";

const ENABLED = "enabled";
const DISABLED = "disabled";
export const ACTIVITY_STATE = {
  ENABLED,
  DISABLED
};

export const SPLIT_CONTAINER_SECTION_NS_CLASS = "split__container--section-ns";

export const getLeftSplitStyles = ({
  hasBrandingAssets,
  showInitialPreview
}) => ({
  minHeight: hasBrandingAssets || showInitialPreview ? 120 : 0
});

export const INPUT_COMMON_CLASS = "input--common";
export const INPUT_CLASSES = `f5 w-100 ${INPUT_COMMON_CLASS} input-reset bg-white--hover br-6 pointer color-text--primary`;

export const ERROR_PILL = {
  status: STATUS.DANGER,
  theme: UI_THEME.SLIM,
  copy: "Error",
  customClasses: {
    container: "flex items-end justify-end"
  }
};

export const EDITOR_PREVIEW_ID = "formerEditor--preview";

export const PB_COMPONENT_CLASS = "pb-component";

export const OVERSCROLL_CONTENT = "overscrollContent";

const ICON = "icon";
const BUTTON = "button";
export const COPY_ACTION_TYPE = {
  ICON,
  BUTTON
};

export const DEFAULT_CHECKOUT_ACTION_CLASSES = {
  container: "",
  button:
    "fw6 br-6 ph3 pv2 border-box input-reset ba bg-transparent pointer f5 dib link items-center flex flex-shrink-0 w-100"
};

export const TOAST_ERROR_CONFIG = {
  appearance: "error",
  autoDismiss: true
};

export const EMPTY_SELECT_OPTION = {
  id: null,
  label: "None"
};

export const LINK_CLASSES = "link__underline color-primary pointer fw6";

export const SLIM_CTA_PROMPT_CLASSES = {
  header: "f4 black-70 fw6",
  subheader: "f6 black-70 lh-copy",
  container:
    "w-100 flex flex-column justify-between ba br2 hairline-1-dashed pa3 items-start bg-white",
  actionsWrapper: "pt2"
};

export const CHECKBOX_LABELS = {
  CHECKED: "Checked",
  NOT_CHECKED: "Not checked"
};

export const TOGGLE_LABELS = {
  ON: "On",
  OFF: "Off"
};

export const VIDEO_MODAL_WRAPPER_ONLY_CLASSES = {
  container: "cell-grow flex flex-column justify-center black-80 db pointer",
  content: "flex flex-row justify-between items-center",
  copy: "f6 fw6 pr1"
};

export const LOGO_DIMENSIONS = {
  width: 200,
  height: 48
};

export const WIDE_RIGHT_HIGHLIGHT_CLASSES = {
  left: "w-100 w-30-l fl flex flex-column",
  right: "w-100 w-70-l fr pt3 pt0-l"
};
