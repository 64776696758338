import { ORGANIZATION } from "./models";

export const MOCK_UUID = "00000000-0000-0000-0000-000000000000";

export const MOCK_RESOURCE = {
  resourceUUID: MOCK_UUID,
  resource: ORGANIZATION
};

export const TEST_PARAMS = { test: true };
export const QUERY_PREFIX = {
  addQueryPrefix: true
};

const GET = "get";
const PUT = "put";
const POST = "post";
const DEL = "del";

export const REQUEST_METHOD = {
  GET,
  PUT,
  POST,
  DEL
};
