import jwtDecode from "jwt-decode";
import isEmpty from "lodash/isEmpty";
const JWT_NAME =
  process.env.NODE_ENV === "production"
    ? "priceblocs-jwt"
    : "priceblocs-dev-jwt";

const notStringNull = (value) => value !== "null";

const isValidValue = (value) => !isEmpty(value) && notStringNull(value);

export const setJWT = (jwt) => {
  if (typeof window !== "undefined") {
    try {
      isValidValue(jwt) && window.localStorage.setItem(JWT_NAME, jwt);
    } catch (err) {
      process.env.NODE_ENV === "development" && console.error(err);
    }
  }
};

export const decodeTokenUUID = (jwt) => jwtDecode(jwt).uuid;

export const clearJWT = () => {
  if (typeof window !== "undefined") {
    try {
      window.localStorage.setItem(JWT_NAME, "");
    } catch (err) {
      process.env.NODE_ENV === "development" && console.error(err);
    }
  }
};

export const getJWT = () => {
  let result;
  if (typeof window !== "undefined") {
    try {
      const value = window.localStorage.getItem(JWT_NAME);

      if (isValidValue(value)) {
        result = value;
      }
    } catch (err) {
      process.env.NODE_ENV === "development" && console.error(err);
    }
  }
  return result;
};

export const authBearerToken = () => {
  let result;
  if (typeof window !== "undefined") {
    try {
      const jwt = window.localStorage.getItem(JWT_NAME);
      result = { Authorization: `Bearer ${jwt}` };
    } catch (err) {
      /**
       * Note: error here likely due to localStorage not being accessible within an embed
       */
      process.env.NODE_ENV === "development" && console.error(err);
    }
  }

  return result;
};

const PERSONAL = "personal";
const TEAM = "team";
export const ACCOUNT_TYPES = {
  PERSONAL,
  TEAM
};
