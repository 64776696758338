export const ATTACHMENT = "attachment";
export const AUDIENCE = "audience";
export const DATASOURCE = "datasource";
export const CAMPAIGN = "campaign";
export const VIEW = "view";
export const USER = "user";
export const ORGANIZATION = "organization";
export const DASHBOARD = "dashboard";
export const PAYMENT_METHOD = "payment_method";
export const COUPON = "coupon";
export const PRODUCT = "product";
export const PRICE = "price";
export const FEATURE = "feature";
export const MANIFEST = "manifest";
export const FEATURE_GROUP = "feature_group";
export const FIELD_GROUP = "field_group";
export const PROMOTION_CODE = "promotion_code";

export const SHORT_LINK = "short_link";
export const PAYMENT_LINK = "payment_link";
export const TAX_RATE = "tax_rate";
export const SHIPPING_RATE = "shipping_rate";

export const CONTACTLESS_CONFIG = "contactless_config";
export const ENTITLEMENT_CONFIG = "entitlement_config";
export const MERCHANT_ACCOUNT = "merchant_account";
export const LEGAL_CONTENT = "legal_content";
export const EDITABLE_CONTENT = "editable_content";
export const INTEGRATION = "integration";
export const METADATA = "metadata";
export const APPLICATION = "application";
// Reducer constants
export const APP = "app";
export const AUTH = "auth";
// Pseudo models
export const PAGE = "page";
export const SALE = "sale";
export const SECTION = "section";
export const LINK = "link";
export const FILE = "file";

/**
 * Use to convert the reducer model key name to the UI model name
 */
export const MODEL_RESOURCES = {
  MANIFEST: { name: MANIFEST, key: MANIFEST },
  FEATURE: { name: FEATURE, key: FEATURE },
  SHORT_LINK: { name: "shortLink", key: SHORT_LINK },
  COUPON: { name: COUPON, key: COUPON },
  TAX_RATE: { name: "taxRate", key: TAX_RATE },
  SHIPPING_RATE: { name: "shippingRate", key: SHIPPING_RATE },
  PROMOTION_CODE: { name: "promotionCode", key: PROMOTION_CODE },
  FEATURE_GROUP: { name: "featureGroup", key: FEATURE_GROUP },
  MERCHANT_ACCOUNT: { name: "merchantAccount", key: MERCHANT_ACCOUNT },
  LEGAL_CONTENT: { name: "legalContent", key: LEGAL_CONTENT },
  EDITABLE_CONTENT: { name: "editableContent", key: EDITABLE_CONTENT },
  CONTACTLESS_CONFIG: { name: "contactlessConfig", key: CONTACTLESS_CONFIG },
  ENTITLEMENT_CONFIG: { name: "entitlementConfig", key: ENTITLEMENT_CONFIG },
  APPLICATION: {
    name: APPLICATION,
    key: APPLICATION
  }
};
