const LINK = "link";
const PAGE = "page";
const PAY = "pay";
const CHECKOUT_SUCCESS = "checkout_success";
const CHECKOUT_COMPLETED = "checkout_completed";
const CHECKOUT_ERROR = "checkout_error";
const CHECKOUT_CONTENT = "checkout_content";
const BILLING_MANAGE_STATUS = "billing_manage_status";

export const ROUTE_CONTEXT = {
  LINK,
  PAGE,
  PAY,
  CHECKOUT_SUCCESS,
  CHECKOUT_ERROR,
  CHECKOUT_COMPLETED,
  CHECKOUT_CONTENT,
  BILLING_MANAGE_STATUS
};

export const SERVER_REQ_PARAMS = [
  "subdomain",
  "alias",
  "route",
  "checkout",
  "url",
  /**
   * Context of the page request
   * - link | page | checkout success etc
   */
  "context",
  /**
   * Whether to append a url fragment to the request
   */
  "fragment",
  "pathname",
  "test",
  "minPageParams",
  "minDocumentParams"
];

export const ENCRYPTED_QUERY_PARAM_KEYS = [
  "session",
  "recovery",
  "prefill",
  "upsell",
  "campaign"
];

export const UNENCRYPTED_QUERY_PARAM_KEYS = [
  "customer",
  "customer_email",
  "email",
  "client_reference_id",
  "promo_code",
  "metadata",
  "success_url"
];

export const CHECKOUT_SESSION_KEYS = [
  ...ENCRYPTED_QUERY_PARAM_KEYS,
  ...UNENCRYPTED_QUERY_PARAM_KEYS
];
