export const APP = "app";
export const GOOGLE = "google";
export const SLACK = "slack";
export const STRIPE = "stripe";
export const SHOPIFY = "shopify";
export const SQUARESPACE = "squarespace";
export const ZAPIER = "zapier";
export const REWARDFUL = "rewardful";
export const DOCUSIGN = "docusign";

export default {
  APP,
  GOOGLE,
  SLACK,
  STRIPE,
  SHOPIFY,
  SQUARESPACE,
  ZAPIER,
  REWARDFUL,
  DOCUSIGN
};
