import { getConfig } from "utils/env";
import pick from "lodash/pick";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import QS from "qs";
import { ENV } from "./constants/env";
import { ensureLeadingSlash } from "./route";
import { CHECKOUT_SESSION_KEYS } from "utils/constants/server";

export const getProtocol = (env = process.env.NODE_ENV) =>
  env === ENV.PRODUCTION ? `https://` : "http://";

export const getWebClientRoot = () =>
  getConfig("WEB_CLIENT_ROOT").split("://")[1];

export const parseSubdomain = (host) => {
  const subdomainParts = host.split(".");
  const sliceIndexEnd =
    subdomainParts.length > 3 ? subdomainParts.length - 2 : 1;

  return subdomainParts.slice(0, sliceIndexEnd).join(".");
};

export const getSubdomainFromHost = (host, env = process.env.NODE_ENV) => {
  const webClientRoot = getWebClientRoot();
  let result;
  /**
   * In development if we are on the root domain then subdomain default is the app subdomain
   */
  if (env !== ENV.PRODUCTION && host === webClientRoot) {
    result = getConfig("APP_SUBDOMAIN");
  } else {
    const subdomain = parseSubdomain(host);
    const protocolParts = compact(subdomain.split("://"));

    const hasProtocol = protocolParts.length > 1;

    result = hasProtocol ? protocolParts[1] : protocolParts[0];
  }

  return result;
};

export const getCheckoutSessionQueryParams = (url) => {
  const qsParams = url && url.split("?")[1];
  const params = qsParams ? QS.parse(qsParams) : {};
  return pick(params, CHECKOUT_SESSION_KEYS);
};

export const isPlatformSubdomain = (subdomain) =>
  Boolean(subdomain && subdomain === getConfig("APP_SUBDOMAIN"));

/**
 * NOTE: used to return undefined from this function - why?
 * - related to _document rendering?
 */
// @deprecated
export const renderParamsFromReq = ({ req, route, alias }) => {
  const result = {};
  if (route) {
    result.route = route;
  }
  if (alias) {
    result.alias = alias;
  }

  const headers = req && req.headers;

  const url = req && req.url;
  if (headers && headers.host) {
    const { host } = headers;
    /**
     * Pick out checkout related params like
     * - session
     * - recovery
     * - email
     * - etc.
     */
    const checkout = getCheckoutSessionQueryParams(url);
    if (!isEmpty(checkout)) {
      result.checkout = checkout;
    }

    result.subdomain = getSubdomainFromHost(host);
  }

  /**
   * If subdomain is not platform
   * - and theres no route
   * - then swap alias for route
   * Why?
   * - We can't use subdomain and alias at the same time
   * - Subdomain takes precedent
   * - we swap the alias to be the route
   * - http://getdevplan.localhost:3000/new
   * - { subdomain: 'getdevplan', alias: 'new' } -> { subdomain: 'getdevplan', route: '/new' }
   * Otherwise it would be like this
   * - http://localhost:3000/getdevplan/new
   * - { route: '/new', alias: 'getdevplan', subdomain: 'priceblocs' }
   */
  if (!isPlatformSubdomain(result.subdomain) && !result.route) {
    result.route = ensureLeadingSlash(result.alias);
    delete result.alias;
  }

  return result;
};

export const getSubdomainRoot = (subdomain, env = process.env.NODE_ENV) => {
  const protocol = getProtocol(env);
  const webClientRoot = getWebClientRoot();
  const ctxSubdomain = isPlatformSubdomain(subdomain) ? "" : `${subdomain}.`;
  return `${protocol}${ctxSubdomain}${webClientRoot}`;
};

export const getSubdomainOrDomain = (url = "") => {
  const fragment = url.split("://")[1];

  let result = "";

  if (fragment) {
    const fragmentParts = compact(fragment.split("."));
    const subdomainParts = fragmentParts.slice(0, fragmentParts.length - 2);
    if (subdomainParts.length) {
      result = subdomainParts.join(".");
    } else {
      result = fragmentParts[0];
    }
  }

  return result;
};

const stripProtocol = (val) => val.replace(/(http:\/\/|https:\/\/)/g, "");

export const getRouteSubdomainOrAlias = (
  url = window.location.href,
  clientRoot = getConfig("WEB_CLIENT_ROOT")
) => {
  const currentURL = new URL(url);
  const rootWithoutProtocol = stripProtocol(clientRoot);
  const hasSubdomain = currentURL.host !== rootWithoutProtocol;

  if (hasSubdomain) {
    const subdomain = compact(currentURL.host.split(rootWithoutProtocol))[0];
    const sanitizedSubdomain = compact(subdomain.split(".")).join(".");
    return {
      subdomain: sanitizedSubdomain
    };
  } else {
    return { alias: compact(currentURL.pathname.split("/"))[0] };
  }
};
