import PATHS from "utils/paths";
import trimStart from "lodash/trimStart";
import { getJWT } from "utils/auth";
import isError from "lodash/isError";
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty";
import QS from "qs";
import { MODEL_PARAM_KEY_MAP } from "./constants/query";
import {
  APPLICATION,
  CAMPAIGN,
  ATTACHMENT,
  PRODUCT,
  PRICE,
  PAGE,
  LINK,
  SHORT_LINK,
  COUPON,
  SALE,
  TAX_RATE,
  FILE,
  SECTION,
  SHIPPING_RATE,
  LEGAL_CONTENT,
  INTEGRATION
} from "utils/constants/models";
import { getConfig } from "utils/env";
import { useRouter } from "next/router";
import { CHECKOUT_SESSION_KEYS } from "utils/constants/server";

const BRACKET_REG_EX_TEST = /^\[/;

/**
 * redirectTo param with multiple QS parameters should be URI encoded so that they can be preserved
 * e.g. full path
 * http://localhost:3000/login?email=shane@priceblocs.com&redirectTo=%2Fplan%3Fuuid%3D08ab38f1-5635-410e-b39b-bce636707804%26model%3Dgoal"
 * unencoded redirectTo: /plan?uuid=08ab38f1-5635-410e-b39b-bce636707804&model=goal
 * @param {Object} router
 * @param {Object} router.query
 * @param {String} fallback
 */
export const getRedirectTo = (router, fallback = PATHS.HUB) => {
  const { redirectTo } = router.query;

  // Ensure that theres only one leading slash
  return redirectTo
    ? `/${trimStart(decodeURIComponent(redirectTo), "/")}`
    : fallback;
};

export const redirectTo = (router, fallback = PATHS.HUB) => {
  const href = getRedirectTo(router, fallback);
  const to = href;

  router && router.push(href, to, { shallow: false });
};

export const authAndRedirect = (
  { router, actions, cb },
  successRoute = PATHS.HUB,
  errorRoute = PATHS.LOGIN
) => {
  try {
    Boolean(getJWT()) &&
      actions.fetchUserDetails(true, (err, response) => {
        cb && cb(err, response);
        if (router && router.replace) {
          if (isError(err)) {
            router.replace(errorRoute);
          } else {
            router.replace(successRoute);
          }
        }
      });
  } catch (error) {
    console.log("auth and redirect error");
  }
};

export const authLeftRight = (left, right) => (getJWT() ? left : right);

/**
 * Prepare the pathname and the parameterized asPath value to be used by next router
 * - extend with any query string params which are passed
 * router.push(pathname, asPath);
 * @param {Object} params
 * @param {String} params.route
 * @param {String} params.asPath
 * @param {Object} queryParams
 */
export const getRouteParamPaths = ({ route, asPath }, queryParams) => {
  const stringified = QS.stringify(queryParams, { addQueryPrefix: true });
  const asPathRoot = asPath.split("?")[0];

  return {
    pathname: `${route}${stringified}`,
    asPath: `${asPathRoot}${stringified}`
  };
};

export const hideOverlay = (router) => {
  const { uuid, modelUUID, overlay, model, ...remainder } = router.query;

  const asPath = router.asPath.split("?")[0];
  const routePaths = getRouteParamPaths(
    {
      route: router.route,
      asPath
    },
    remainder
  );
  router.push(routePaths.pathname, routePaths.asPath);
};

export const hideNavBar = (pathname) => {
  return pathname.includes(PATHS.APPLICATIONS_PAGES_SHOW);
};

export const joinPath = (root, fragment) =>
  root ? `${root}${fragment}` : fragment;

export const interpolateRouteWithParams = (route, variables, queryParams) => {
  const stringified =
    typeof queryParams === "object" && !isEmpty(queryParams)
      ? QS.stringify(queryParams, { addQueryPrefix: true })
      : "";

  return `${interpolateRoute(route, variables)}${stringified}`;
};

export const interpolateRoute = (route, variables) => {
  return route
    ? route
        .split("?")[0]
        .split("/")
        .map((fragment) => {
          if (BRACKET_REG_EX_TEST.test(fragment)) {
            const sanitized = fragment.replace(/(\[|\])/gi, "");

            const sanitizedParts = sanitized.split("-");
            const hasNamedAttribute = sanitizedParts.length > 1;
            const endIndex = sanitizedParts.length - 1;

            const variable = hasNamedAttribute
              ? sanitizedParts.slice(0, endIndex).join("-")
              : sanitizedParts[0];

            const accessor = hasNamedAttribute
              ? sanitizedParts.slice(endIndex)[0]
              : null;

            if (variable && accessor && variables[variable]) {
              return variables[variable][accessor];
            } else if (variable && variables[variable]) {
              return variables[variable];
            } else {
              throw new Error(
                `Unable to interpolate route fragment ${fragment}`
              );
            }
          } else {
            return fragment;
          }
        })
        .join("/")
    : "";
};

export const extractQSFromAsPath = (asPath) => {
  const parts = asPath.split("?");
  return parts && parts.length > 1 ? QS.parse(parts[1]) : {};
};

/**
 * The application path is one under which pages are built
 * @param {String} pathname
 * @returns Boolean
 */
export const isApplicationPath = (pathname) =>
  /\/applications\//.test(pathname);

const APP_LINK_CREATE_PARAMS = ["links", "create"];
/**
 * Certain app routes will have a grey bg - check for those paths here
 * - Currently only scoped to application routes
 * @param {String} pathname
 * @returns Boolean
 */
export const isGreyBGPath = ({ pathname, query = {} }) => {
  const isAppPath = isApplicationPath(pathname);
  const queryParams = get(query, "param", []);

  const exception =
    queryParams &&
    queryParams.length &&
    queryParams.join("/") === APP_LINK_CREATE_PARAMS.join("/");

  return isAppPath && !exception;
};

export const isModelCreateRoute = (section, asPath) => {
  const re = new RegExp(`/${section}s/create`);
  return re.test(asPath);
};

export const isAltModelCreateRoute = (section, param) => {
  return param.indexOf(section) > -1 && param.indexOf("create") > -1;
};

export const isTestRoute = (route) => /\/test\//.test(route);

export const isDocsRoute = (path) => /^\/docs/.test(path);

const UUID = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
const ALPHANUMERIC = /^[A-Za-z0-9-_]{1,100}/;
const SHORT_ID = /^[A-Za-z0-9-_]{21}$/;
const STRIPE_COUPON = /^[A-Za-z0-9-_]{8}$/;
const STRIPE_PRICE = /^(price_|plan_)/;
const STRIPE_PRODUCT = /^prod_/;
const STRIPE_TAX_RATE = /^txr_/;
const STRIPE_SHIPPING_RATE = /^shr_/;
const REFERENCE_PATTERN = {
  UUID,
  ALPHANUMERIC,
  SHORT_ID,
  STRIPE_COUPON,
  STRIPE_PRICE,
  STRIPE_PRODUCT,
  STRIPE_TAX_RATE,
  STRIPE_SHIPPING_RATE
};

/**
 * NOTE: add to adding new reosurce documentation
 */
export const MODEL_PATTERN_MAP = {
  [`${APPLICATION}s`]: REFERENCE_PATTERN.UUID,
  [`${ATTACHMENT}s`]: REFERENCE_PATTERN.UUID,
  [`${FILE}s`]: REFERENCE_PATTERN.UUID,
  [`${CAMPAIGN}s`]: REFERENCE_PATTERN.UUID,
  [`${INTEGRATION}s`]: REFERENCE_PATTERN.UUID,
  [`${PRODUCT}s`]: REFERENCE_PATTERN.STRIPE_PRODUCT,
  [`${PRICE}s`]: REFERENCE_PATTERN.STRIPE_PRICE,
  [`${SECTION}s`]: REFERENCE_PATTERN.ALPHANUMERIC,
  [`${PAGE}s`]: REFERENCE_PATTERN.UUID,
  [`${LINK}s`]: REFERENCE_PATTERN.UUID,
  [`${kebabCase(SHORT_LINK)}s`]: REFERENCE_PATTERN.UUID,
  [`${kebabCase(LEGAL_CONTENT)}s`]: REFERENCE_PATTERN.UUID,
  [`${COUPON}s`]: REFERENCE_PATTERN.STRIPE_COUPON,
  [`${SALE}s`]: REFERENCE_PATTERN.UUID,
  [`${kebabCase(TAX_RATE)}s`]: REFERENCE_PATTERN.STRIPE_TAX_RATE,
  [`${kebabCase(SHIPPING_RATE)}s`]: REFERENCE_PATTERN.STRIPE_SHIPPING_RATE
};

export const reduceQueryParamReferences = ({ param = [], ...query }) => {
  const initQuery = query ? { ...query } : {};
  const queryParams = Array.isArray(param) ? param : [];
  return queryParams.reduce((memo, paramValue, paramIx) => {
    const paramKey = param[paramIx - 1];

    if (
      paramKey &&
      MODEL_PATTERN_MAP[paramKey] &&
      MODEL_PATTERN_MAP[paramKey].test(paramValue)
    ) {
      const referenceKey = MODEL_PARAM_KEY_MAP[paramKey];

      if (!referenceKey) {
        throw new Error(
          `No reference key for param key ${paramKey}. Check the MODEL_PARAM_KEY_MAP mapping.`
        );
      }

      memo[referenceKey] = paramValue;
    }

    return memo;
  }, initQuery);
};

export const isCreateRoute = (params = []) => params.indexOf("create") > -1;

export const getModelIDFromQuery = (model, query) => {
  const references = reduceQueryParamReferences(query);
  const lookupKey = MODEL_PARAM_KEY_MAP[`${kebabCase(model)}s`];
  const modelId = references[lookupKey];

  return modelId;
};

// Need to go back to the main app - no rewrites on local
export const getMainAppPath = (path) =>
  process.env.NODE_ENV !== "production"
    ? `${getConfig("WEB_CLIENT_ROOT")}${path}`
    : path;

export const urlWithCurrentLocationCancel = (url = "") => {
  if (url) {
    if (typeof window !== "undefined") {
      const urlParts = url.split("?");
      const qsParams = url && urlParts[1];
      const params = qsParams ? QS.parse(qsParams) : {};
      const fullParamsSet = { ...params, cancel_url: window.location.href };
      return `${urlParts[0]}${QS.stringify(fullParamsSet, {
        addQueryPrefix: true
      })}`;
    } else {
      return url;
    }
  } else {
    return "";
  }
};

export const ensureLeadingSlash = (route) =>
  route ? (/^\//.test(route) ? route : `/${route}`) : "/";

export const getTestTrackingQS = (params = {}) => {
  return QS.stringify(
    {
      cancel_url: typeof window !== "undefined" ? window.location.href : "",
      ...params
    },
    { addQueryPrefix: true }
  );
};

export const parseAsPathParams = (asPath) => QS.parse(asPath.split("?")[1]);

export const isLinksRoute = (route) =>
  /^\/links\//.test(route) || /^\/test\/links\//.test(route);

export const isPayRoute = (route) =>
  /^\/pay\//.test(route) || /^\/test\/pay\//.test(route);

export const isLinkFieldsRoute = (route) =>
  /\/pre$/.test(route) || /\/post$/.test(route);

export const getQuerySessionParams = (query) => {
  const params = pick(query, CHECKOUT_SESSION_KEYS);
  delete params.success_url;

  return params;
};

export const useQuerySessionParams = () => {
  const { query } = useRouter();

  return getQuerySessionParams(query);
};

export const extendQS = (url = "", params) => {
  const parts = url && url.split("?");
  const hasParts = parts && parts.length;
  if (!hasParts) {
    return "";
  }
  const parsed = parts && parts.length > 1 ? QS.parse(parts[1]) : {};

  return `${parts[0]}${QS.stringify(
    {
      ...parsed,
      ...params
    },
    { addQueryPrefix: true }
  )}`;
};
