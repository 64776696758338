import get from "lodash/get";
import round from "lodash/round";
import { DESKTOP, INPUT_HEIGHT, MOBILE, PHONE } from "utils/constants/ui";
import {
  OVERLAY_INITIAL,
  DEFAULT_OVERLAY_STYLES
} from "utils/constants/overlay";
const FIXED_CLASS = "fixed";

export const getWindow = (path) => {
  if (typeof window !== "undefined") {
    return get(window, path);
  }

  return {};
};

export const isSm = () => {
  return getWindow("innerWidth") <= PHONE;
};

export const isMobile = () => {
  return getWindow("innerWidth") < DESKTOP;
};

export const isPhone = () => {
  return getWindow("innerWidth") < MOBILE;
};

export const formFactorAction = () =>
  getWindow("innerWidth") <= 480 ? "Tap" : "Click";

/**
 * gridCellWidth
 * - Calculate the width of cells within product feeds
 * @param {bool} isMobile
 * @param {bool} isPhone
 * @param {number} columns
 */
export const gridCellWidth = (isMobile, isPhone, columns = 4) => {
  return isMobile
    ? isPhone
      ? "calc(100%)"
      : "calc(33% - 0.5rem)"
    : `calc(${100 / columns}% - 0.5rem)`;
};

export const scrollTop = (node) => {
  if (node && node.scrollIntoView) {
    node.scrollIntoView();
  }
};

export const scrollTo = (x = 0, y = 0) => {
  typeof window !== "undefined" && window.scrollTo(x, y);
};

const bodyHasClassname = (classname) =>
  document.getElementsByTagName("body")[0].classList.contains(classname);

const bodyAddClassname = (classname) =>
  document.getElementsByTagName("body")[0].classList.add(classname);

const bodyRemoveClassname = (classname) =>
  document.getElementsByTagName("body")[0].classList.remove(classname);

export const isServer = () => typeof window === "undefined";

export const isDev = () => process.env.NODE_ENV === "development";

export const fixBody = () => bodyAddClassname(FIXED_CLASS);

export const unfixBody = () => bodyRemoveClassname(FIXED_CLASS);

/**
 * If an overlay is present already
 * Then render with a transparent background
 * @param {Boolean} present
 * @returns
 */
export const getOverlayStyles = (present) => {
  return present ? { backgroundColor: "transparent" } : DEFAULT_OVERLAY_STYLES;
};

export const initialOverlayPresent = () => {
  let hasInitialOverlay = false;
  if (typeof document !== "undefined") {
    const overlays = document.getElementsByClassName(OVERLAY_INITIAL);
    hasInitialOverlay = overlays.length > 0;
  }

  return hasInitialOverlay;
};

const OVERLAY_CLASS = "overlay";
export const setOverlayBodyClass = (uuid, cb) => {
  if (!bodyHasClassname(OVERLAY_CLASS)) {
    bodyAddClassname(OVERLAY_CLASS);
    bodyAddClassname(`${OVERLAY_CLASS}-${uuid}`);

    cb && cb();
  }
};

/**
 * Be transparent if there is a modal class
 * and the modal uuid is not the one passed
 */
export const shouldBeTransparent = (uuid) =>
  bodyHasClassname(OVERLAY_CLASS) &&
  !bodyHasClassname(`${OVERLAY_CLASS}-${uuid}`);

export const unsetOverlayBodyClass = (uuid, cb) => {
  if (
    bodyHasClassname(OVERLAY_CLASS) &&
    bodyHasClassname(`${OVERLAY_CLASS}-${uuid}`)
  ) {
    bodyRemoveClassname(OVERLAY_CLASS);
    bodyRemoveClassname(`${OVERLAY_CLASS}-${uuid}`);
    cb && cb();
  }
};

export const getAppliedDimensions = ({
  width,
  height,
  widthFactor,
  heightFactor
}) => {
  const ctxWidth = !Number.isNaN(width) ? width : 0;
  const ctxHeight = !Number.isNaN(height) ? height : 0;
  let appliedWidth = round(ctxWidth * widthFactor);
  appliedWidth = !Number.isNaN(appliedWidth) ? appliedWidth : 0;
  let appliedHeight = round(ctxWidth * heightFactor);
  appliedHeight = !Number.isNaN(appliedHeight) ? appliedHeight : 0;

  return {
    height: ctxHeight,
    width: ctxWidth,
    appliedWidth,
    appliedHeight
  };
};

export const reduceStyleOverrides = (styles) => {
  const result = [];
  if (styles) {
    if (styles.backgroundColor) {
      result.push(`background-color: ${styles.backgroundColor} !important;`);
      result.push(`border-color: ${styles.backgroundColor} !important;`);
    }
    if (styles.color) {
      result.push(`color: ${styles.color} !important;`);
    }
    if (styles.border) {
      result.push(`border-color: ${styles.border} !important;`);
    }
  }

  return result.join(" ");
};

export const refocusRef = (ref) => {
  if (ref.current) {
    const clientRect = ref.current.getBoundingClientRect();
    const shouldRefocus = clientRect.y < window.innerHeight - INPUT_HEIGHT;
    if (shouldRefocus) {
      ref.current.focus();
    }
  }
};
