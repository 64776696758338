const CONFIG = {
  STRIPE_PRODUCT_ID_VIEWER: process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ID_VIEWER,
  STRIPE_PRODUCT_ID_CREATOR: process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ID_CREATOR,
  WEB_CLIENT_ROOT: process.env.NEXT_PUBLIC_WEB_CLIENT_ROOT,
  API_ROOT: process.env.NEXT_PUBLIC_API_ROOT,
  APP_SUBDOMAIN: process.env.NEXT_PUBLIC_APP_SUBDOMAIN,
  APP_ALIAS: process.env.NEXT_PUBLIC_APP_ALIAS,
  STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  PRICE_BLOCS_PUB_KEY: process.env.NEXT_PUBLIC_PRICE_BLOCS_PUB_KEY,
  PRICE_BLOCS_PUB_KEY_TEST: process.env.NEXT_PUBLIC_PRICE_BLOCS_PUB_KEY_TEST,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  GOOGLE_ANALYTICS_KEY: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY,
  GOOGLE_RECAPTCHA_KEY: process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY,
  FACEBOOK_PIXEL_KEY: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_KEY,
  MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  SENTRY_ORG: process.env.SENTRY_ORG,
  FILEPICKER_KEY: process.env.NEXT_PUBLIC_FILEPICKER_KEY,
  CDN_RENDER_OVERRIDE: process.env.NEXT_PUBLIC_CDN_RENDER_OVERRIDE,
  DOCSEARCH_KEY: process.env.NEXT_PUBLIC_DOCSEARCH_KEY,
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  BASE_PATH: process.env.NEXT_PUBLIC_BASE_PATH || "",
  PRICE_BLOCS_UPGRADE_SHORT_LINK_ID:
    process.env.NEXT_PUBLIC_PRICE_BLOCS_UPGRADE_SHORT_LINK_ID || "",
  PRICE_BLOCS_DIGITAL_CONTENT_SHORT_LINK_ID:
    process.env.NEXT_PUBLIC_PRICE_BLOCS_DIGITAL_CONTENT_SHORT_LINK_ID || ""
};

export const getConfig = (key) => {
  const envVar = CONFIG[key];
  if (!envVar) {
    throw new Error(`No runtime variable for: ${key}`);
  }
  return envVar;
};
