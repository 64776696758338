export const NAV_BAR = 64;
export const TOP_CONTROL = 54;
export const MEDIUM = 768;
export const SMALL = 425;
export const layoutStyles = (additional = 0) => {
  return { height: `calc(100vh - ${NAV_BAR + additional}px)` };
};

export const DURATION = 0.15;

export const BLUE_PRIMARY = "#5D8BFF";
export const BLUE_SECONDARY = "#5580EF";
export const BLUE_FADE = "#A6C0FF";

export const PURPLE_PRIMARY = "#5D8BFF";
export const PURPLE_SECONDARY = "#5580EF";

export const GOLD_PRIMARY = "#F4C018";
export const GOLD_SECONDARY = "#F9DF8B";

export const BLUE_SCHEME = {
  primary: BLUE_PRIMARY,
  secondary: BLUE_SECONDARY,
  fade: BLUE_FADE
};

export const PURPLE_SCHEME = {
  primary: PURPLE_PRIMARY,
  secondary: PURPLE_SECONDARY
};

export const GOLD_SCHEME = {
  primary: GOLD_PRIMARY,
  secondary: GOLD_SECONDARY
};

export const COLOR_SCHEMES = {
  BLUE: BLUE_SCHEME,
  PURPLE: PURPLE_SCHEME,
  GOLD: GOLD_SCHEME
};

export const COLOR_SCHEME_KEYS = Object.keys(COLOR_SCHEMES).reduce(
  (memo, key) => {
    memo[key] = key;
    return memo;
  },
  {}
);

const SUCCESS_GREEN = "#4BB543";
const GREEN_05 = "#CBF4CA";
const WARNING_05 = "#ffde92";
const WARNING_TEXT = "#bb5504";
const DANGER_05 = "#FF9CA6";
const DANGER_TEXT = "#B02121";
const GOLD_05 = "#FFDA68";
const GOLD_TEXT = "#A17C05";

export const COLORS = {
  text: "#333",
  textSecondary: "#787777",
  // Primary and secondary are text colors
  primary: "#383838",
  secondary: "#979797",
  deselected: "#9E9E9E",
  disabledAction: "#D5D5D5",
  disabledActionBorder: "#EAEAEA",
  disabled: "#F9F9F9",
  grip: "#E1E1E1",
  black30: "#E5E5E5",
  purple: BLUE_PRIMARY,
  purpleFade: "rgba(127, 86, 195, 0.05)",
  // ------------------- main line
  //  -------- blue scale start
  emphasis: "#EDF2FF",
  ctaBlue: "#f6f8ff",
  dark_blue: "#054A91",
  blue: BLUE_SCHEME.primary,
  blueFade: BLUE_SCHEME.fade,
  blue_hover: BLUE_SCHEME.secondary,
  mid_blue: "#D5BDFD",
  water_blue: "#CAD8FB",
  light_blue: "#ABC1FC",
  sky_blue: "#E8EBFE",
  royal_blue: "#3245C3",
  forest_green: "#0C761D",
  //  -------- blue scale end
  gold_border: "rgba(244, 192, 24, 0.75)",
  gold: GOLD_PRIMARY,
  gold_hover: "#E3B215",
  whitesmoke: "#FAFAFA",
  deactivated: "#BEBEBE",
  tabSecondaryHoverBg: "#FCFCFC",
  tabSecondaryHover: "#F3F3F3",
  // This is rgba(248....)
  content: {
    secondary: "rgb(250, 250, 250)",
    hover: "#EFEFEF"
  },
  disclaimer: "rgb(107, 119, 140)",
  icon: "#555555",
  bg: "rgb(250, 250, 250)",
  smoke_hover: "#EEEEEE",
  success: BLUE_PRIMARY,
  success_hover: "#40AA56",
  danger: "#E33446",
  warning: "#FEAC00",
  danger05: DANGER_05,
  danger05Text: DANGER_TEXT,
  dangerBorder: "#FF8F9B",
  warning05: WARNING_05,
  warning05Text: WARNING_TEXT,
  success05: GREEN_05,
  gold05Text: GOLD_TEXT,
  gold05: GOLD_05,
  purple05: PURPLE_PRIMARY,
  white: "#FFFFFF",
  successGreen: SUCCESS_GREEN
  // ------------------- end
};

export const CC_STYLES = {
  style: {
    base: {
      "color": "#383838",
      "fontFamily": "Avenir Next,Avenir,Helvetica,sans-serif",
      "fontSmoothing": "antialiased",
      "fontSize": "16px",
      "::placeholder": {
        color: "#383838"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

export const getEmphasisBGColor = ({ emphasisIndex, index, preventEmphasis }) =>
  preventEmphasis
    ? COLORS.white
    : emphasisIndex === index
    ? COLORS.emphasis
    : COLORS.white;
